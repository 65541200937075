<script>
import Vue, {
  computed,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  nextTick,
} from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import DrawerBankForm from '/~/components/drawer/components/payment-methods/forms/drawer-bank-form.vue'
import DrawerCardForm from '/~/components/drawer/components/payment-methods/forms/drawer-card-form.vue'
import { useAccess } from '/~/composables/access/use-access'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import router from '/~/router'

export default {
  name: 'payment-methods-add',
  components: {
    DrawerCardForm,
    DrawerBankForm,
    BaseTabs,
    BaseTab,
    BaseAsidePage,
    BaseLoader,
  },
  props: {
    title: {
      type: String,
      default: 'Add Payment Method',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const {
      isMethodAvailable,
      creating,
      everyBankAccountPayable,
      fetchingList: fetching,
      configLoading,
      hasUnavailableMethods,
      everyCreditCardPayable,
    } = usePaymentMethods()
    const { isBankAccountsNavEnabled, isCashrewardsProvider } = useProvider()
    const { withAccessCheck } = useAccess()
    const tab = ref('card')

    const loading = computed(() => {
      return configLoading.value || fetching.value
    })

    const showBankTab = computed(() => {
      return (
        everyBankAccountPayable.value &&
        isShowMethod(PaymentMethodType.bankAccount)
      )
    })

    const showCardTab = computed(() => {
      return (
        everyCreditCardPayable.value &&
        isShowMethod(PaymentMethodType.creditCard)
      )
    })

    const hasAvailableMethods = computed(() => {
      return showBankTab.value || showCardTab.value
    })

    function isShowMethod(type) {
      return isMethodAvailable(type, router.currentRoute.params.source)
    }

    async function onAdded(paymentMethod) {
      const text =
        paymentMethod.type === PaymentMethodType.bankAccount
          ? 'Bank account successfully added'
          : 'Credit card successfully added'

      if (
        paymentMethod.type === PaymentMethodType.bankAccount &&
        isBankAccountsNavEnabled.value
      ) {
        Vue.notify({
          text: 'Your bank account verification has been initiated and an amount between $0.01 and $2.99 will appear on your statement. Please allow up to 24 hours and come back once you have located this amount in order to verify your Bank Account.',
          type: 'success',
          duration: 10000,
        })
      } else {
        Vue.notify({
          text,
          type: 'success',
          duration: 3000,
        })
      }

      if (isCashrewardsProvider.value) {
        await router.replace(route.meta?.back)
      } else {
        await router.replace({
          hash: '#profile-payment-methods',
          params: {
            replace: true,
          },
        })
      }

      bottomSheet.show('method-verify', {
        to: 'menu-modal',
        props: {
          method: paymentMethod,
          title: 'Verify account',
        },
      })
    }

    onBeforeMount(() => {
      withAccessCheck({
        beforeShowModal: async () => {
          await router.push({ name: 'home' })
        },
      })

      if (hasUnavailableMethods(router.currentRoute.params.source)) {
        if (isCashrewardsProvider.value) {
          router.replace({
            name: 'payment-methods',
            query: route.query,
            params: {
              replace: true,
            },
          })
        } else {
          router.replace({
            hash: '#profile-payment-methods',
            query: route.query,
            params: {
              replace: true,
            },
          })
        }
      }
      tab.value = showCardTab.value ? 'card' : 'bank'
      emitter.on('payment-methods:created', onAdded)
    })

    onBeforeUnmount(() => {
      emitter.off('payment-methods:created', onAdded)
    })

    return {
      creating,
      fetching,
      tab,
      loading,
      showBankTab,
      showCardTab,
      hasAvailableMethods,
      isShowMethod,
      onAdded,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page ref="baseAsidePage" :title="title" no-padding>
    <base-loader v-if="loading" class="py-[30px]" fullwidth />
    <base-tabs
      v-else-if="showCardTab && showBankTab"
      v-model="tab"
      :disabled="creating"
      class="h-full"
    >
      <base-tab name="Credit/Debit Card" value="card">
        <drawer-card-form class="px-5 pt-2.5" />
      </base-tab>

      <base-tab name="Bank Account" value="bank">
        <drawer-bank-form class="px-5 pt-2.5" />
      </base-tab>
    </base-tabs>
    <div v-else-if="showCardTab || showBankTab" class="flex h-full">
      <drawer-card-form v-if="showCardTab" class="px-5 pt-2.5" />
      <drawer-bank-form v-if="showBankTab" class="px-5 pt-2.5" />
    </div>
    <div v-else class="p-[30px] text-center font-bold">
      No payment methods available
    </div>
  </base-aside-page>
</template>
